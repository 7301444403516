import React from "react";
import style from "./index.module.css";
import model from "./data";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: model,
      selected: -1,
    };
  }

  onClickItem(index) {
    console.log("click item:", index);
    this.setState({ selected: index });
  }

  onExitPlay() {
    this.setState({ selected: -1 });
    console.log("....");
  }

  render() {
    const { data, selected } = this.state;
    console.log("selected", selected);
    return (
      <div className={style.App}>
        {selected >= 0 && (
          <div className={style.videoBox}>
            <video
              className={style.video}
              src={data[selected].videoUrl}
              onPause={() => this.onExitPlay()}
              controls
              autoPlay
              loop
            />
          </div>
        )}
        <div className={style.listBox}>
          <div className={style.title}>棱镜系统改善动作库</div>
          {data.map((item, index) => {
            return (
              <div
                className={style.item}
                key={item.videoId}
                onClick={(e) => this.onClickItem(index)}
              >
                <div
                  className={style.imgBox}
                  style={{ backgroundImage: "url('./videoBox.png')" }}
                >
                  <img className={style.img} src={item.imgUrl} alt="" />
                </div>
                <div className={style.name}>
                  {item.name} {selected === index ? " D" : ""}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default App;
