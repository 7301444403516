const data = [
  {
    "name": "01滚轴股四头肌",
    "videoId": "YDCF_070",
    "videoUrl": "http://video.zhijianfit.com/sv/1ce01e6c-17e3e5166ce/1ce01e6c-17e3e5166ce.mp4",
    "imgUrl": "http://video.zhijianfit.com/image/default/82616943590B4E288505A80567E01A70-6-2.png"
  },
  {
    "name": "02滚轴小腿三头肌",
    "videoId": "YDCF_071",
    "videoUrl": "http://video.zhijianfit.com/sv/448a28c1-17e3e51670c/448a28c1-17e3e51670c.mp4",
    "imgUrl": "http://video.zhijianfit.com/image/default/8808CE6A86B44AD1A1A54D234283F8BA-6-2.png"
  },
  {
    "name": "03多方向蝎式",
    "videoId": "YDCF_072",
    "videoUrl": "http://video.zhijianfit.com/sv/1ac39374-17e3e516859/1ac39374-17e3e516859.mp4",
    "imgUrl": "http://video.zhijianfit.com/image/default/48046D9B61744A51BD51EDC4C99AF6BD-6-2.png"
  },
  {
    "name": "04滚轴臀大肌",
    "videoId": "YDCF_073",
    "videoUrl": "http://video.zhijianfit.com/sv/2096e3d8-17e3e5168b9/2096e3d8-17e3e5168b9.mp4",
    "imgUrl": "http://video.zhijianfit.com/image/default/A7FADD29AFE7442888C23D5364E1A817-6-2.png"
  },
  {
    "name": "05滚轴内收肌群",
    "videoId": "YDCF_074",
    "videoUrl": "http://video.zhijianfit.com/sv/13b6a1f9-17e3e516902/13b6a1f9-17e3e516902.mp4",
    "imgUrl": "http://video.zhijianfit.com/image/default/BB62CE26F98F4E5DBB2BE996F7341683-6-2.png"
  },
  {
    "name": "06滚轴腘绳肌群",
    "videoId": "YDCF_075",
    "videoUrl": "http://video.zhijianfit.com/sv/20e961cc-17e3e516928/20e961cc-17e3e516928.mp4",
    "imgUrl": "http://video.zhijianfit.com/image/default/4BEE115B2ABC4666864A948F984DA4F6-6-2.png"
  },
  {
    "name": "07蚌式",
    "videoId": "YDCF_076",
    "videoUrl": "http://video.zhijianfit.com/sv/1b4a3553-17e3e516961/1b4a3553-17e3e516961.mp4",
    "imgUrl": "http://video.zhijianfit.com/image/default/927089BD8E7847309C56833773744A70-6-2.png"
  },
  {
    "name": "08侧桥内收肌训练",
    "videoId": "YDCF_077",
    "videoUrl": "http://video.zhijianfit.com/sv/18c115aa-17e3e516abb/18c115aa-17e3e516abb.mp4",
    "imgUrl": "http://video.zhijianfit.com/image/default/FB63F94DCF514F5EA508F06A454587CD-6-2.png"
  },
  {
    "name": "09花生球腰椎按压",
    "videoId": "YDCF_065",
    "videoUrl": "http://video.zhijianfit.com/sv/16eebf20-17e3e5163d9/16eebf20-17e3e5163d9.mp4",
    "imgUrl": "http://video.zhijianfit.com/image/default/5CDB3F197174498790A68111EFBDBDBE-6-2.png"
  },
  {
    "name": "10胶球臀中肌按压",
    "videoId": "YDCF_066",
    "videoUrl": "http://video.zhijianfit.com/sv/5fd59858-17e3e516409/5fd59858-17e3e516409.mp4",
    "imgUrl": "http://video.zhijianfit.com/image/default/E2FE34B7643547FA8A6FFE68CA0B1254-6-2.png"
  },
  {
    "name": "11胶球梨状肌按压",
    "videoId": "YDCF_067",
    "videoUrl": "http://video.zhijianfit.com/sv/55d570fd-17e3e51658c/55d570fd-17e3e51658c.mp4",
    "imgUrl": "http://video.zhijianfit.com/image/default/C2E27A4534564105932E3D8C9F2C31C9-6-2.png"
  },
  {
    "name": "12腹部举重",
    "videoId": "YDCF_064",
    "videoUrl": "http://video.zhijianfit.com/sv/79cfea1-17e3e5163a6/79cfea1-17e3e5163a6.mp4",
    "imgUrl": "http://video.zhijianfit.com/image/default/256138F1E2D449AA88DE9A65C5BB7E05-6-2.png"
  },
  {
    "name": "13死虫子",
    "videoId": "YDCF_068",
    "videoUrl": "http://video.zhijianfit.com/sv/45538645-17e3e516613/45538645-17e3e516613.mp4",
    "imgUrl": "http://video.zhijianfit.com/image/default/B3DB34941C9A43B1A13AA15BE27405DE-6-2.png"
  },
  {
    "name": "14平板支撑",
    "videoId": "YDCF_069",
    "videoUrl": "http://video.zhijianfit.com/sv/60be63e9-17e3e51664d/60be63e9-17e3e51664d.mp4",
    "imgUrl": "http://video.zhijianfit.com/image/default/A8A739EF42754601BFC4FFEA8C56F08F-6-2.png"
  },
  {
    "name": "15肩部I式",
    "videoId": "YDCF_052",
    "videoUrl": "http://video.zhijianfit.com/sv/25d8e149-17e3e515ecc/25d8e149-17e3e515ecc.mp4",
    "imgUrl": "http://video.zhijianfit.com/image/default/168B169BFD0343E0B133CC798791BD86-6-2.png"
  },
  {
    "name": "16肩部O式",
    "videoId": "YDCF_053",
    "videoUrl": "http://video.zhijianfit.com/sv/4eee8e5d-17e3e515eec/4eee8e5d-17e3e515eec.mp4",
    "imgUrl": "http://video.zhijianfit.com/image/default/BB8C49D7042348E7A14228FDEF36D72D-6-2.png"
  },
  {
    "name": "17肩部S式",
    "videoId": "YDCF_054",
    "videoUrl": "http://video.zhijianfit.com/sv/1c8442a1-17e3e515efd/1c8442a1-17e3e515efd.mp4",
    "imgUrl": "http://video.zhijianfit.com/image/default/F8A544DC235841A284AC3B9A9E50F409-6-2.png"
  },
  {
    "name": "18胸大肌牵拉",
    "videoId": "YDCF_055",
    "videoUrl": "http://video.zhijianfit.com/sv/1db944a4-17e512addef/1db944a4-17e512addef.mp4",
    "imgUrl": "http://video.zhijianfit.com/image/default/8DEC3CD88EE045CF963A7F2F842E28B1-6-2.png"
  },
  {
    "name": "19背阔肌牵拉",
    "videoId": "YDCF_056",
    "videoUrl": "http://video.zhijianfit.com/sv/2da36317-17e512ade03/2da36317-17e512ade03.mp4",
    "imgUrl": "http://video.zhijianfit.com/image/default/07905BB57A2A44B0BB01486C35EB1FE7-6-2.png"
  },
  {
    "name": "20肱二头肌牵拉",
    "videoId": "YDCF_057",
    "videoUrl": "http://video.zhijianfit.com/sv/1c63b8cb-17e512ade08/1c63b8cb-17e512ade08.mp4",
    "imgUrl": "http://video.zhijianfit.com/image/default/5DFC25F7A29849B1BD5B08257DA47D33-6-2.png"
  },
  {
    "name": "21肱三头肌牵拉",
    "videoId": "YDCF_058",
    "videoUrl": "http://video.zhijianfit.com/sv/56a502d8-17e3e516136/56a502d8-17e3e516136.mp4",
    "imgUrl": "http://video.zhijianfit.com/image/default/7809F2ECA51D4AF69EEFC9E39C5C7F0A-6-2.png"
  },
  {
    "name": "22肩袖PNF牵拉",
    "videoId": "YDCF_061",
    "videoUrl": "http://video.zhijianfit.com/sv/2f58c2e3-17e3e516217/2f58c2e3-17e3e516217.mp4",
    "imgUrl": "http://video.zhijianfit.com/image/default/93C6644773B94D7890BDCFEFC3991ABD-6-2.png"
  },
  {
    "name": "23蹲姿提踵",
    "videoId": "YDCF_096",
    "videoUrl": "http://video.zhijianfit.com/sv/3a864fe4-17e3e517171/3a864fe4-17e3e517171.mp4",
    "imgUrl": "http://video.zhijianfit.com/image/default/3640E3541AFE4F09BD43D4935FF79C93-6-2.png"
  },
  {
    "name": "24后撤步斜向转体",
    "videoId": "YDCF_083",
    "videoUrl": "http://video.zhijianfit.com/sv/4ac96da7-17e3e516ce2/4ac96da7-17e3e516ce2.mp4",
    "imgUrl": "http://video.zhijianfit.com/image/default/1C17ECD54C83489684A70B7B1D9852B6-6-2.png"
  },
  {
    "name": "25胶球冈下肌内外旋",
    "videoId": "YDCF_059",
    "videoUrl": "http://video.zhijianfit.com/sv/2dfd32c5-17e3e516171/2dfd32c5-17e3e516171.mp4",
    "imgUrl": "http://video.zhijianfit.com/image/default/98AA64E482B9497F91ABFE9662F57E6F-6-2.png"
  },
  {
    "name": "26胶球小圆肌内外旋",
    "videoId": "YDCF_060",
    "videoUrl": "http://video.zhijianfit.com/sv/56dd194b-17e3e5161a0/56dd194b-17e3e5161a0.mp4",
    "imgUrl": "http://video.zhijianfit.com/image/default/3935C0A70AED495C9FD056BC8250FAA6-6-2.png"
  },
  {
    "name": "27箱式转体",
    "videoId": "YDCF_063",
    "videoUrl": "http://video.zhijianfit.com/sv/20761af6-17e3e51637a/20761af6-17e3e51637a.mp4",
    "imgUrl": "http://video.zhijianfit.com/image/default/8956BCA5D2CE4DC4BECB3AC1F1F80A5D-6-2.png"
  },
  {
    "name": "28绕肩A/B/C",
    "videoId": "YDCF_078",
    "videoUrl": "http://video.zhijianfit.com/sv/4cad052d-17e512ade19/4cad052d-17e512ade19.mp4",
    "imgUrl": "http://video.zhijianfit.com/image/default/C652981496684276A74E61842A6FC7B9-6-2.png"
  },
  {
    "name": "29前后熊爬",
    "videoId": "YDCF_097",
    "videoUrl": "http://video.zhijianfit.com/sv/2d20be23-17e3e51726f/2d20be23-17e3e51726f.mp4",
    "imgUrl": "http://video.zhijianfit.com/image/default/29BDBB0C5E16481E8914797266E08C8B-6-2.png"
  },
  {
    "name": "30招财猫",
    "videoId": "YDCF_081",
    "videoUrl": "http://video.zhijianfit.com/sv/4de99ea7-17e3e516b8b/4de99ea7-17e3e516b8b.mp4",
    "imgUrl": "http://video.zhijianfit.com/image/default/F27BADB4AE2A4F47B9DEF34170AC1FCC-6-2.png"
  },
  {
    "name": "31徒手大风车",
    "videoId": "YDCF_082",
    "videoUrl": "http://video.zhijianfit.com/sv/3c1f362-17e3e516ca0/3c1f362-17e3e516ca0.mp4",
    "imgUrl": "http://video.zhijianfit.com/image/default/2565C303E55444309D865D1D868D0938-6-2.png"
  },
  {
    "name": "32翻书",
    "videoId": "YDCF_062",
    "videoUrl": "http://video.zhijianfit.com/sv/40b3fb41-17e3e51631d/40b3fb41-17e3e51631d.mp4",
    "imgUrl": "http://video.zhijianfit.com/image/default/146FCD644B3D407A95983A35A757A38B-6-2.png"
  },
  {
    "name": "33剪蹲转体",
    "videoId": "YDCF_084",
    "videoUrl": "http://video.zhijianfit.com/sv/20d88c32-17e3e516cf4/20d88c32-17e3e516cf4.mp4",
    "imgUrl": "http://video.zhijianfit.com/image/default/7E672B7F877A49FBBE533C53CDAFC647-6-2.png"
  },
  {
    "name": "34猫式",
    "videoId": "YDCF_085",
    "videoUrl": "http://video.zhijianfit.com/sv/458a7942-17e3e516d3a/458a7942-17e3e516d3a.mp4",
    "imgUrl": "http://video.zhijianfit.com/image/default/56CBC14F1593436D84CEDBA612658098-6-2.png"
  },
  {
    "name": "35大跨步后仰",
    "videoId": "YDCF_086",
    "videoUrl": "http://video.zhijianfit.com/sv/2456c32a-17e3e516d6d/2456c32a-17e3e516d6d.mp4",
    "imgUrl": "http://video.zhijianfit.com/image/default/E66858983797432AA547CD590EA1952E-6-2.png"
  },
  {
    "name": "36猴蹲",
    "videoId": "YDCF_087",
    "videoUrl": "http://video.zhijianfit.com/sv/ea1ab5e-17e3e516e68/ea1ab5e-17e3e516e68.mp4",
    "imgUrl": "http://video.zhijianfit.com/image/default/E1444D15DC5B4E8CA00154CEF67BCA4E-6-2.png"
  },
  {
    "name": "37蝎式伸展",
    "videoId": "YDCF_088",
    "videoUrl": "http://video.zhijianfit.com/sv/609b8a06-17e3e516e8e/609b8a06-17e3e516e8e.mp4",
    "imgUrl": "http://video.zhijianfit.com/image/default/C65FFAB8F1F147CAB67EE2E3A4FC98C1-6-2.png"
  },
  {
    "name": "38直腿爬行",
    "videoId": "YDCF_089",
    "videoUrl": "http://video.zhijianfit.com/sv/5109e403-17e3e516ee3/5109e403-17e3e516ee3.mp4",
    "imgUrl": "http://video.zhijianfit.com/image/default/147D1A1AD246477CA53853E1D062A68C-6-2.png"
  },
  {
    "name": "39交替A字支撑",
    "videoId": "YDCF_090",
    "videoUrl": "http://video.zhijianfit.com/sv/15573ae4-17e3e516f1b/15573ae4-17e3e516f1b.mp4",
    "imgUrl": "http://video.zhijianfit.com/image/default/2BED0C7209B84AECAA6C5CFC5B941B76-6-2.png"
  },
  {
    "name": "40侧桥转体",
    "videoId": "YDCF_091",
    "videoUrl": "http://video.zhijianfit.com/sv/6153ac62-17e3e516f57/6153ac62-17e3e516f57.mp4",
    "imgUrl": "http://video.zhijianfit.com/image/default/52A4B282EA8D4841B0BD8DF3C944ECE4-6-2.png"
  },
  {
    "name": "41侧步提膝稳定",
    "videoId": "YDCF_092",
    "videoUrl": "http://video.zhijianfit.com/sv/2b0f9f8e-17e3e517069/2b0f9f8e-17e3e517069.mp4",
    "imgUrl": "http://video.zhijianfit.com/image/default/1AF95083D4B641DC8414FD680887E7D2-6-2.png"
  },
  {
    "name": "42十字支撑",
    "videoId": "YDCF_093",
    "videoUrl": "http://video.zhijianfit.com/sv/533637cc-17e3e5170a9/533637cc-17e3e5170a9.mp4",
    "imgUrl": "http://video.zhijianfit.com/image/default/E3B569A983264EB48ED7E5E9C020D785-6-2.png"
  },
  {
    "name": "43剪蹲交替垂直跳",
    "videoId": "YDCF_094",
    "videoUrl": "http://video.zhijianfit.com/sv/18deed0a-17e3e51712c/18deed0a-17e3e51712c.mp4",
    "imgUrl": "http://video.zhijianfit.com/image/default/AEFDFC3D0EBE4795864EE842C4133C08-6-2.png"
  },
  {
    "name": "44脚十字",
    "videoId": "YDCF_095",
    "videoUrl": "http://video.zhijianfit.com/sv/50eadee-17e3e51714e/50eadee-17e3e51714e.mp4",
    "imgUrl": "http://video.zhijianfit.com/image/default/E67A06F968684952BD8E138F0A631DCB-6-2.png"
  }
]

export default data